:root {
	--bgcolor: rgb(255, 255, 255);
}
.fjalla-one-regular {
  font-family: "Fjalla One", sans-serif;
  font-weight: 400;
  font-style: normal;
  
}
.about{
  max-width: 600px;
}
.shadow-r{
  box-shadow: 10px 0 10px -10px rgba(0, 0, 0, 0.5);
}
/*css menu*/
.menu {
	font-size: 1.2rem;
	position: relative;
	text-transform: uppercase;
	transition: all 300ms ease;
	cursor: pointer;
}
.menu:hover {
	transform: skew(20deg);
}
/*
.menu::before {
  content: attr(data-name);
	position: absolute;
	top: 0;
	left: 120px;
	height: 1rem;
	overflow: hidden;
	transition: all 300ms ease;
	padding-left: 20px;
}*/
.menu:hover::before {
	color: rgb(48, 48, 48);
  transform: skew(20deg);
}

.menu::after {
	content: "";
	height: 4px;
	width: 0;
	position: absolute;
	top: 100%;
	left: 60%;
	transform: translate(-50%, -50%);
	background: rgb(0, 0, 0);
	transition: all 300ms ease;
}
.menu:hover::after {
	width: 120%;
	outline: 5px  rgb(2, 0, 36);
}
.linkAling {
	display: inline-flex;  /* Use flexbox for vertical alignment */
            align-items: center;   /* Center the items vertically */
            text-decoration: none; /* Remove underline from anchor text */
            gap: 0.5rem;           /* Space between text and icon */
}
.boxE:hover{
	box-shadow: 6px -5px 8px -4px rgba(0, 0, 0, 0.2), 14px -2px 8px -4px rgba(0, 0, 0, 0.2), 10px 4px 8px rgba(0, 0, 0, 0.2);

}
.boxE{
	padding: 30px 30px 30px 0px;
	transition: box-shadow 0.3s ease;
}
.proyectB{
	padding: 20px 20px 20px 0px;
	background-image: linear-gradient(to right, white 80%, #f4f4f4);
            /* Animate over 3 seconds, infinite loop */
	
	
	
}
.proyectB:hover{
	/*animation: gradient-animation 1s ease;*/
	
}
.sociallogos{
	margin-top: 8px;
}
.scrolling-box {

	scroll-behavior: smooth;
	
  }
  html {
	scroll-behavior: smooth;
	scroll-margin-top: 200px;
}
@keyframes gradient-animation {
	0% {
		background-position: 0px; /* Posición inicial del fondo */
	}
	100% {
		background-position: 210px; /* Posición final del fondo */
	}
}