@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  display: flex;
  height: 100vh;
}

.list{
  margin-top: 10px;
}
.firstSpa{
  margin-top: 0px;
}
.nameMenu{
  display: none;
}
.scrollSm{
  scroll-margin-top: 60px;
}
@media (max-width: 1024px) {
  .nameMenu{
    display: block;
    width: 50px;
    height: 20px;
    background-image: url('../public/logoed.jpg');
    background-repeat: round;
    margin-right: 10px;
    }
  
  .list {
  position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: var(--bgcolor);
    padding: 10px 25px;
    z-index: 1000;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  .list {
   display: flex;
   margin-top: 0px;
  }
  .list > li {
    padding: 0px 20px 0px 0px;
  }
  .relleno{
    height: 160px;
  }
  .cont1name{
    margin-top: 80px;
    padding-left: 0rem !important;
  }
  .text-5xl {
    font-size: 1.6rem;
    
  }
  .text-2xl{
    font-size: 1.2rem;
    margin-top: 0px;
  }
  main{
    margin-top: 30px;
  }
  .shadow-r {
    box-shadow: none !important;
}     
.menu{
  font-size: 0.7rem !important;
}

  
}

.scrolling {
  width: 70%;
  padding: 20px;
  overflow-y: auto;

  display: block;
}



